import moment from "moment"

const Status = (props) => {
  const { start, end } = props

  return (
    <div className="flex justify-center">
      <div className="p-2 bg-custom-green text-white text-xs font-bold whitespace-pre-wrap text-center rounded mr-2">
        {start
          ? `${moment(start).format("YYYY年M月D日")}\n${moment(start).format(
              "HH:mm"
            )}`
          : "ー年ー月ー日\nー:ー"}
      </div>
      <div className="p-2 bg-custom-red text-white text-xs font-bold whitespace-pre-wrap text-center rounded">
        {end
          ? `${moment(end).format("YYYY年M月D日")}\n${moment(end).format(
              "HH:mm"
            )}`
          : "ー年ー月ー日\nー:ー"}
      </div>
    </div>
  )
}

export default Status
