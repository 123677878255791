import * as API from "common/api"
import * as COMMONS from "common/common"
import { RightOutlined } from "@ant-design/icons"
import { Card, Image } from "antd"
// import styled from "styled-components"
import { TagComponent } from "components"

// const CustomDescriptions = styled(Descriptions)`
//   .ant-descriptions-view {
//     border-color: ${(props) => props.$publicSettings?.PRIMARY_COLOR};
//   }
//   .ant-descriptions-row {
//     border-color: ${(props) => props.$publicSettings?.PRIMARY_COLOR};
//   }

//   .ant-descriptions-item-label,
//   .ant-descriptions-bordered .ant-descriptions-item-content {
//     border-color: ${(props) => props.$publicSettings?.PRIMARY_COLOR};
//   }
// `

const Category = (props) => {
  const { publicSettings, category } = props

  return (
    <Card
      style={{ borderColor: publicSettings?.PRIMARY_COLOR }}
      bodyStyle={{ padding: 0 }}
      className="drop-shadow"
      cover={
        <div className="relative">
          {category?.categoryImages && category?.categoryImages?.length > 0 ? (
            <Image
              preview={false}
              src={`${API.CATEGORIES_UPLOADS_URL}${category.categoryImages[0]?.picUrl}`}
              fallback="/no-image.png"
              height={130}
              width="100%"
              className="object-cover rounded-t"
              style={{ padding: "1px" }}
              alt={`${COMMONS.DEFAULT_SYSTEM_TYPE}画像`}
            />
          ) : (
            <Image
              preview={false}
              src="/no-image.png"
              height={130}
              width="100%"
              className="object-cover"
              style={{ padding: "1px" }}
              alt={`${COMMONS.DEFAULT_SYSTEM_TYPE}画像`}
            />
          )}
          <div className="absolute left-0 top-0">
            {category?.categoryAreas && category?.categoryAreas?.length > 0 ? (
              <div className="flex flex-wrap">
                {category.categoryAreas.map((area) => (
                  <TagComponent
                    key={area?.contents}
                    backgroundColor={publicSettings?.PRIMARY_LIGHT_COLOR}
                    borderColor={publicSettings?.PRIMARY_COLOR}
                    color={publicSettings?.PRIMARY_COLOR}
                    text={area?.contents || ""}
                  />
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      }
    >
      <div className="py-2">
        <p className="text-center text-sm font-bold h-12 leading-6 line-clamp-2 px-4">
          {category?.title || ""}
        </p>
      </div>
      <div
        className="flex justify-around items-center w-full p-4"
        style={{ backgroundColor: publicSettings?.PRIMARY_COLOR }}
      >
        <div className="flex-1">
          <p className="text-center text-base font-bold text-white">
            詳細・予約
          </p>
        </div>
        <div className="flex-none">
          <RightOutlined className="text-white" />
        </div>
      </div>
    </Card>
  )
}

export default Category
